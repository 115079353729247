import request from '../request'

//差旅目的地列表
export const travel_address_index_request = p => {
    return request({
        method:'GET',
        url:'travel_address/index',
        params: p
    })
}

//添加差旅目的地
export const travel_address_add_request = d => {
    return request({
        method:'POST',
        url:'travel_address/add',
        data: {
            name: d.name
        }
    })
}

//删除差旅目的地
export const travel_address_del_request = id => {
    return request({
        method:'DELETE',
        url:'travel_address/del',
        data: {
            id: id
        }
    })
}

//修改差旅目的地
export const travel_address_edit_request = d => {
    return request({
        method:'PUT',
        url:'travel_address/edit',
        data: {
            id: d.id,
            name: d.name
        }
    })
}

//获取差旅目的地详情
export const travel_address_details_request = id => {
    return request({
        method:'GET',
        url:'travel_address/details',
        params: {
            id
        }
    })
}